import { createBrowserRouter, Navigate, Outlet} from "react-router-dom";

import Dashboard from "./pages/Dashboard/Dashboard.js";
import DashboardTeam from "./pages/Dashboard/Team";
import DashboardRequests from "./pages/Dashboard/Requests";
import DashboardMessages from "./pages/Dashboard/Messages.js";
import DashboardMyRequests from "./pages/Dashboard/MyRequests/MyRequests.js";
import MyRequestsActive from "./pages/Dashboard/MyRequests/Active.js";
import MyRequestsPending from "./pages/Dashboard/MyRequests/Pending.js";
import MyRequestsArchived from "./pages/Dashboard/MyRequests/Archived.js";
import MyRequestsCreate from "./pages/Dashboard/MyRequests/Create.js";
import DashboardDuplicates from "./pages/Dashboard/Duplicates.js";
import DashboardAbout from "./pages/Dashboard/About.js";
import DashboardLearning from "./pages/Dashboard/Learning.js";
import DashboardStore from "./pages/Dashboard/Store/Store.js";
import DashboardSettings from "./pages/Dashboard/Settings/Settings.js";
import SettingsAccount from "./pages/Dashboard/Settings/Account.js";
import SettingsCompany from "./pages/Dashboard/Settings/Company.js";
import SettingsBilling from "./pages/Dashboard/Settings/Billing.js";
import StoreAddons from "./pages/Dashboard/Store/Addons.js";
import StorePlans from "./pages/Dashboard/Store/Plans.js";
import StoreCart from "./pages/Dashboard/Store/Cart.js";
import ComingSoon from "./pages/ComingSoon";
import Home from "./pages";
import Businesses from "./pages/Businesses";
import HomepageRequests from "./pages/Requests.js";
import Pricing from "./pages/Pricing";
import Faq from "./pages/Faq";
import Login from "./pages/Login";
import CreatePassword from "./pages/CreatePassword";
import ResetPassword from "./pages/ResetPassword";
import ForgotPassword from "./pages/ForgotPassword";
import EmailVerified from "./pages/EmailVerified";

import AdminHomepage from "./pages/Admin/Homepage";
import AdminUsers from "./pages/Admin/Users";
import AdminRequests from "./pages/Admin/Requests";
import AdminCompanies from "./pages/Admin/Companies";
import AdminLogs from "./pages/Admin/Logs";
import AdminLearning from "./pages/Admin/Learning";
import GuestLayout from "./components/Router/GuestLayout";
import PrivateLayout from "./components/Router/PrivateLayout";
// import { MdDashboardCustomize } from "react-icons/md";
import EditRequest from "./pages/Dashboard/EditRequest";
import TermsConditions from "./pages/TermsConditions";
import PrivacyPolicy from "./pages/PrivacyPolicy";
import Notifications from "./pages/Dashboard/Notifications";

import DashboardBuyerMatches from "./pages/Dashboard/BuyerMatches.js";
import CreateRequest from "./pages/CreateRequest.js";
import Maintenance from "./pages/Maintenance.js";
import DemoRequest from "./pages/DemoRequest.js";

const router = createBrowserRouter([
  {
    path: "/",
    element: <Outlet />,
    children: [
      {
        path: "/",
        element: <Home />,
      },
      {
        path: "businesses",
        element: <Businesses />,
      },
      {
        path: "requests",
        element: <HomepageRequests />,
      },
      {
        path: "faq",
        element: <Faq />,
      },
      {
        path: "terms-and-conditions",
        element: <TermsConditions />,
      },
      {
        path: "privacy-policy",
        element: <PrivacyPolicy />,
      },
      {
        path: "request-demo",
        element: <DemoRequest />,
      },
      {
        path: "create-request",
        element: <CreateRequest />,
      },
      {
        path: "/maintenance",
        element: <Maintenance />,
      },
    ],
  },
  {
    path: "/",
    element: <GuestLayout />,
    children: [
      {
        path: "login",
        element: <Login />,
      },
      {
        path: "create-password/:id_encrypt",
        element: <CreatePassword />,
      },
      {
        path: "password-reset/:token",
        element: <ResetPassword />,
      },
      {
        path: "forgot-password",
        element: <ForgotPassword />,
      },
      {
        path: "email-verified",
        element: <EmailVerified />,
      },
      {
        path: "pricing",
        element: <Pricing />,
      },
    ],
  },
  {
    path: "/app/",
    element: <PrivateLayout />,
    children: [
      {
        path: "notifications",
        element: <Notifications />,
      },
      {
        path: "dashboard",
        element: <Dashboard />,
      },
      // {
      //   path: "about",
      //   element: <DashboardAbout />,
      // },
      {
        path: "requests/:id?",
        element: <DashboardRequests />,
      },
      {
        path: "my-requests/",
        element: <DashboardMyRequests />,
        children: [
          {
            path:"active/:id?",
            element: <MyRequestsActive />
          },
          {
            path:"archived",
            element: <MyRequestsArchived />
          },
          {
            path:"pending",
            element: <MyRequestsPending />
          },
          {
            path:"create",
            element: <MyRequestsCreate />
          },
        ]
      },
      {
        path: "my-requests/edit/:id",
          element: <EditRequest />
      },
      {
        path: "duplicates",
        element: <DashboardDuplicates />,
      },
      {
        path: "learning",
        element: <DashboardLearning />,
      },
      {
        path: "store",
        element: <DashboardStore />,
        children: [
          {
            path:"addons",
            element: <StoreAddons />
          },
          {
            path:"plans",
            element: <StorePlans />
          },
          {
            path:"cart",
            element: <StoreCart />
          }
        ]
      },
      {
        path: "settings/",
        element: <><DashboardSettings /></>,
        children: [
          {
            path:"account",
            element: <SettingsAccount />
          },
          {
            path:"company",
            element: <SettingsCompany />
          },
          {
            path:"billing",
            element: <SettingsBilling />
          },
        ]
      },
      {
        path: "team",
        element: <DashboardTeam />,
      },
      {
        path: "messages/:id?",
        element: <DashboardMessages />,
      },
      {
        //TODO admin layout para prevenir entrar no module admin através do url
        path: "admin/",
        element: <><AdminHomepage /><Navigate to={"users"} replace /></>,
        children: [
          {
            path:"users",
            element: <AdminUsers />,
          },
          {
            path:"users/:id",
            element: <AdminUsers />,
          },
          {
            path:"companies",
            element: <AdminCompanies />,
          },
          {
            path:"companies/:id",
            element: <AdminCompanies />,
          },
          {
            path:"requests",
            element: <AdminRequests />,
          },
          {
            path: "requests/edit/:id",
              element: <EditRequest />
          },
          {
            path:"logs",
            element: <AdminLogs />,
          },
          {
            path:"learning",
            element: <AdminLearning />,
          },
        ]
      },
      {
        path: "buyer-matches",
        element: <DashboardBuyerMatches />,
      },
    ],
  },
]);

export default router;

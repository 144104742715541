export const USER_ROLES = {
  REGULAR: 1,
  FORTIMY: 2,
  ADMIN: 3,
};

export const MANAGE_USERS_FILTERS = {
  ALL: 1,
  ACTIVE: 2,
  PENDING: 3,
  BLOCKED: 4,
};

export const MANAGE_COMPANIES_FILTERS = {
  ALL: 1,
  ACTIVE: 2,
  PENDING: 3,
  BLOCKED: 4,
};

export const MANAGE_REQUESTS_FILTERS = {
  UNARCHIVED: 0,
  ARCHIVED: 1,
  ALL: 2,
};

export const MANAGE_LEARNING_FILTERS = {
  THEME: 1,
  VIDEO: 2,
};

export const REQUESTS_SORT_BY = {
  RELEVANCE: 1,
  HIGHEST: 2,
  LATEST: 3,
  MORE: 4,
};

export const ADMIN_TABS_PATH = {
  MANAGE_USERS: '/app/admin/users',
  LOGBOOK: '/app/admin/logs',
  MANAGE_COMPANIES: '/app/admin/companies',
  MANAGE_REQUESTS: '/app/admin/requests',
  MANAGE_LEARNING: '/app/admin/learning',
};

export const SETTINGS_TABS_PATH = {
  ACCOUNT_SETTINGS: '/app/settings/account',
  COMPANY_SETTINGS: '/app/settings/company',
  BILLING_SETTINGS: '/app/settings/billing',
};

export const STORE_TABS_PATH = {
  ADDONS_STORE: '/app/store/addons',
  PLANS_STORE: '/app/store/plans',
  CART_STORE: '/app/store/cart',
};

export const MY_REQUESTS_TABS_PATH = {
  ACTIVE: '/app/my-requests/active',
  ARCHIVED: '/app/my-requests/archived',
  PENDING: '/app/my-requests/pending',
  CREATE: '/app/my-requests/create',
};

export const APP_PATHS = {
  ADMIN: '/app/admin',
  BUYER_MATCHES: '/app/buyer-matches',
  ABOUT: '/app/about',
  REQUESTS: '/app/requests',
  MY_REQUESTS: '/app/my-requests',
  DASHBOARD: '/app/dashboard',
  MESSAGES: '/app/messages',
  DUPLICATES: '/app/duplicates',
  TEAM: '/app/team',
  LEARNING: '/app/learning',
  STORE: '/app/store',
  SETTINGS: '/app/settings',
  NOTIFICATIONS: '/app/notifications',
};

export const CONFIRMATION_MODAL_ACTION = {
  ARCHIVE: 1,
  DELETE: 2,
};

export const API_PATHS = {
  PROFILE_IMAGE: `${process.env.REACT_APP_BACKEND_URL_IMG}/uploads/users`,
};

export const PLANS = {
  INDIVIDUAL_BASIC: 1,
  BUSINESS_BASIC: 2,
  BUSINESS_ELITE: 3,
};

export const PLAN_TYPES = {
  INDIVIDUAL: 1,
  BUSINESS: 2,
};

export const COMPANY_ROLES = {
  ADMIN: 1,
  USER: 2,
};

export const LOCATION_TYPE = {
  DISTRICT: 1,
  MUNICIPALITY: 2,
  PARISH: 3,
  ZONE: 4,
};

export const AMENITIES = {
  MORE: 8
};

export const REQUEST_TYPE = {
  FOR_SALE: 1,
  FOR_RENT: 2,
};

export const REPORT_TYPE = {
  FAKE_REQUEST: 1,
  FAKE_IDENTITY: 2,
  OTHER: 3,
};

export const PERMANENCE_PERIODS = {
  NO_LOYALTY: 1,
  THREE_MONTHS: 2,
  SIX_MONTHS: 3,
};

export const RANGE_FILTER = {
  MIN_BUDGET: 1,
  MAX_BUDGET: 2,
  MIN_AREA: 3,
  MAX_AREA: 4
}

export const STORE_ITEMS = {
  HIGHLIGHT: 1,
  COLOUR_HIGHLIGHT: 2,
  REQUEST: 3,
  USER: 4,
}

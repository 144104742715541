const NotificationCard = ({ notification, isFirst = false }) => 
{
    return (
        <div className={`${!isFirst && 'border-t-2'} flex items-center gap-2 md:gap-10 py-5 p-4 md:p-10 w-full`}>
            <div className={`${notification.seen && 'opacity-0'} bg-green-500 rounded-full w-3 h-3 md:w-4 md:h-4 shrink-0`} />
            <div className={`${(notification.title).toLowerCase() == 'new buyer match found' ? 'bg-customPurple' : 'bg-primary' } flex items-center justify-center text-white text-4xl md:text-6xl rounded-full w-12 h-12 md:w-20 md:h-20 shrink-0`}>
                <img src={notification.icon} alt={notification.title}/>
            </div>
            <div className="flex-grow">
                <div className="flex items-center justify-between">
                    <h2 className="md:text-lg">{notification.title}</h2>
                    <div className="text-primary text-sm md:text-base">
                        {notification.time}
                    </div>
                </div>
                <p className="text-disabled text-sm md:text-base">{notification.description}</p>
            </div>
        </div>
    )
}

export default NotificationCard